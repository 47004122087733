
import { Vue, Options } from 'vue-class-component';
import { SmartForm, SmartFormSummary, PageLoading, TriageSelect } from '@/lib/components';
import { FormSubmission, TriageStatus, FormConfig } from '@/models';
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import {
  PatientFormSubmissionService,
  TriageStatusService,
  AnonymousParticipantFormSubmissionService,
  EncounterService
} from '@/services/api';
import { getSchemaWithInheritedConditions } from '@/helpers/smart-form.helper';
import { i18n, switchLocale } from '@/i18n/i18n';
import { useSessionStore } from '@/stores/session.store';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patient: {
      type: Object,
      default: null
    },

    organisationId: {
      type: String,
      required: true
    },

    patientId: {
      type: String,
      default: null
    },

    participantId: {
      type: String,
      default: null
    },

    formSubmissionId: {
      type: String,
      required: true
    }
  },
  components: {
    SmartForm,
    SmartFormSummary,
    PageLoading,
    TriageSelect
  }
})
export default class PatientFormSubmissionPage extends Vue {
  patientId?: string;
  participantId?: string;
  formSubmissionId!: string;
  sessionStore = useSessionStore();
  loading = true;
  triageLoading = false;
  schema: FormConfig | null = null;
  formId = '';
  completed = false;
  date = '';
  userName = '';
  encounterId = '';
  encounterTriageStatusId: string | null = null;
  clinicId: string | null = null;
  triageStatuses: Array<TriageStatus> = [];
  encounterService: EncounterService = new EncounterService();
  request: CancelTokenSource | null = null;
  prefilledFormSubmission: FormSubmission | null = null;

  patientFormSubmissionService?: PatientFormSubmissionService;
  anonymousParticipantFormSubmissionService?: AnonymousParticipantFormSubmissionService;
  triageStatusService: TriageStatusService = new TriageStatusService();

  smartForm = useSmartFormStore();
  notificationStore = useNotificationStore();

  get config(): FormConfig | null {
    return this.schema ? getSchemaWithInheritedConditions(this.schema) : null;
  }

  async created() {
    this.request = axios.CancelToken.source();
    if (this.patientId) {
      this.patientFormSubmissionService = new PatientFormSubmissionService(this.patientId);
    } else if (!this.patientId && this.participantId) {
      this.anonymousParticipantFormSubmissionService = new AnonymousParticipantFormSubmissionService(
        this.participantId
      );
    }

    await this.loadFormSubmission(this.request.token);

    if (this.clinicId && this.clinicId.length) {
      await this.fetchTriageStatuses(this.clinicId, this.request.token);
    }
    this.request = null;
  }

  beforeUnmount() {
    switchLocale(this.sessionStore.currentUser.locale);
    if (this.request) {
      this.request.cancel();
    }
  }

  async loadFormSubmission(cancelToken: CancelToken) {
    this.loading = true;

    try {
      let response: FormSubmission | null = null;
      if (this.patientId && this.patientFormSubmissionService) {
        response = await this.patientFormSubmissionService.fetch(this.formSubmissionId, {
          cancelToken
        });
      } else if (!this.patientId && this.participantId && this.anonymousParticipantFormSubmissionService) {
        response = await this.anonymousParticipantFormSubmissionService.fetch(this.formSubmissionId, {
          cancelToken
        });
      }

      if (response) {
        if (response.encounter_id) {
          this.encounterId = response.encounter_id;
          const encounter = await this.encounterService.fetch(this.encounterId);
          this.encounterTriageStatusId =
            encounter?.episode_of_care.ophthalmology_details.triage_status_id || null;
          this.clinicId = encounter?.episode_of_care.ophthalmology_details.clinic_id;
        }
        if (response.form) {
          this.schema = response.form.schema || null;
          this.formId = response.form.id;
        }
        if (response.prefill_form_submission) {
          this.prefilledFormSubmission = response.prefill_form_submission;
        }
        this.completed = !!response.completed_at;
        this.date = response.created_at;
        this.userName = `${response.user.given_name} ${response.user.family_name}`;
        this.locale = response.form?.locale || i18n.global.locale.value;
        switchLocale(this.locale);

        this.smartForm.answers = {
          ...this.smartForm.answers,
          [this.formSubmissionId]: response.answers
        };
      }
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.form.error-fetching-form-submission')
      });
    } finally {
      this.loading = false;
    }
  }

  async fetchTriageStatuses(clinicId: string, cancelToken: CancelToken) {
    try {
      const requestConfig: AxiosRequestConfig = {
        params: {
          ...(clinicId ? { 'filter[clinic_id]': clinicId } : {}),
          cancelToken
        }
      };

      this.triageStatuses = (await this.triageStatusService.index(requestConfig)).data;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.error-fetching-triage-status')
      });
    }
  }

  async updatePatientTriageStatus(triageStatusId: string) {
    this.triageLoading = true;

    try {
      const response = await this.encounterService.update(this.encounterId, {
        triage_status_id: triageStatusId
      });
      if (response.episode_of_care.ophthalmology_details.triage_status_id) {
        this.encounterTriageStatusId = response.episode_of_care.ophthalmology_details.triage_status_id;
      }
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.error-fetching-triage-status')
      });
    } finally {
      this.triageLoading = false;
    }
  }
}
