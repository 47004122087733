import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full h-screen items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLoading = _resolveComponent("PageLoading")!
  const _component_SmartForm = _resolveComponent("SmartForm")!
  const _component_SmartFormSummary = _resolveComponent("SmartFormSummary")!
  const _component_TriageSelect = _resolveComponent("TriageSelect")!
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_PageLoading)
        ]))
      : (_ctx.config)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.completed)
              ? (_openBlock(), _createBlock(_component_SmartForm, {
                  key: 0,
                  config: _ctx.config,
                  "form-id": _ctx.formId,
                  "patient-id": _ctx.patientId,
                  "participant-id": _ctx.participantId,
                  "organisation-id": _ctx.organisationId,
                  patient: _ctx.patient,
                  "form-submission-id": _ctx.formSubmissionId,
                  "prefilled-form-submission": _ctx.prefilledFormSubmission
                }, null, 8, ["config", "form-id", "patient-id", "participant-id", "organisation-id", "patient", "form-submission-id", "prefilled-form-submission"]))
              : (_openBlock(), _createBlock(_component_SmartFormSummary, {
                  key: 1,
                  config: _ctx.config,
                  "participant-id": _ctx.participantId,
                  "patient-id": _ctx.patientId,
                  "form-submission-id": _ctx.formSubmissionId,
                  date: _ctx.date,
                  "user-name": _ctx.userName,
                  locale: _ctx.locale
                }, null, 8, ["config", "participant-id", "patient-id", "form-submission-id", "date", "user-name", "locale"])),
            _createVNode(_component_portal, { to: "triage" }, {
              default: _withCtx(() => [
                (_ctx.triageStatuses.length)
                  ? (_openBlock(), _createBlock(_component_TriageSelect, {
                      key: 0,
                      modelValue: _ctx.encounterTriageStatusId,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.encounterTriageStatusId) = $event)),
                        _ctx.updatePatientTriageStatus
                      ],
                      options: _ctx.triageStatuses,
                      loading: _ctx.triageLoading
                    }, null, 8, ["modelValue", "options", "loading", "onUpdate:modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
  ]))
}